




import { Component, Mixins, Prop } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import FieldMixin from '@/components/facts/FieldMixin.vue'

@Component({
  components: {
    DetailField
  }
})
export default class TotalCostRenderer extends Mixins(FieldMixin) {
  @Prop() claimStatus!: string

  get displayValue(): number {
    if (this.claimStatus === 'ACCEPTED') {
      return Number(this.fact.currentValue)
    } else {
      return 0
    }
  }

  get value(): string {
    return this.$t('common.currencySymbol', { amount: this.displayValue.toLocaleString() }).toString()
  }
}
