








import { Component, Vue } from 'vue-property-decorator'
import Stepper from '@/components/Stepper.vue'
import constants from '@/constants'

@Component({
  components: {
    Stepper
  }
})
export default class ViewClaim extends Vue {
  transitionName = constants.transitionNames.SLIDE_LEFT
  steps = 4;

  get step () {
    return this.$route.meta?.step
  }

  created () {
    this.$router.beforeEach((to, from, next) => {
      if (from.meta && 'pageIndex' in from.meta && to.meta && 'pageIndex' in to.meta) {
        this.transitionName = to.meta.pageIndex > from.meta.pageIndex ? constants.transitionNames.SLIDE_LEFT : constants.transitionNames.SLIDE_RIGHT
      } else {
        this.transitionName = constants.transitionNames.FADE
      }
      next()
    })
  }
}
