



























import { Component, Vue } from 'vue-property-decorator'
import DeviceOption from '@/components/DeviceOption.vue'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import { Getter } from 'vuex-class'
import { ClaimRepairModel, RepairModel } from '@/services/api/models/RepairModel'
import { isDateBetween } from '@/services/functions'

const REPAIR_DATE_FACT_ID = 'virtual.repairDate'
const REPAIR_STATUS_FACT_ID = 'outcome.customRepairStatus'
const SERVICE_TYPE_FACT_ID = 'outcome.type'
const SERVICE_TYPE_REPLACEMENT = 'REPLACEMENT'
const CLAIM_STATUS_ACCEPTED = 'ACCEPTED'
const REPAIR_STATUS_COMPLETED = '09'

@Component({
  components: {
    DeviceOption,
    BackDashboardButton
  }
})
export default class ViewClaimList extends Vue {
  @Getter('claim/repairs')
  repairs!: RepairModel[]

  assembleRepairInfo(repair: ClaimRepairModel): RepairInfo {
    return {
      repairDate: repair.facts?.find((f) => f.id === REPAIR_DATE_FACT_ID)?.currentValue,
      status: repair.status,
      repairStatus: repair.facts?.find((f) => f.id === REPAIR_STATUS_FACT_ID)?.currentValue,
      serviceType: repair.facts?.find((f) => f.id === SERVICE_TYPE_FACT_ID)?.currentValue
    }
  }

  assembleAcceptedReplacementCount(repairInfos: RepairInfo[], policyCoverageStartDate: string,
    policyCoverageEndDate: string): number {
    return repairInfos.reduce((n, it) => {
      return +(it.status === CLAIM_STATUS_ACCEPTED && it.repairStatus === REPAIR_STATUS_COMPLETED && it.serviceType === SERVICE_TYPE_REPLACEMENT &&
        isDateBetween(it.repairDate, policyCoverageStartDate, policyCoverageEndDate, true)) + n
    }, 0)
  }

  assembleClaimCount(repairInfos: RepairInfo[], policyCoverageStartDate: string, policyCoverageEndDate: string): number {
    return repairInfos.reduce((n, it) => {
      return +(it.status === CLAIM_STATUS_ACCEPTED && isDateBetween(it.repairDate, policyCoverageStartDate, policyCoverageEndDate, true)) + n
    }, 0)
  }

  claimInfo(repair: RepairModel): ClaimInfo {
    const repairInfos = repair?.claims?.map((it) => {
      return this.assembleRepairInfo(it)
    })
    return {
      deviceName: repair.device.nickname,
      claimNumber: this.assembleClaimCount(repairInfos, repair.policyCoverageStartDate, repair.policyCoverageEndDate),
      repairInfos: repairInfos,
      acceptedReplacementCount: this.assembleAcceptedReplacementCount(repairInfos, repair.policyCoverageStartDate, repair.policyCoverageEndDate)
    }
  }
}

interface ClaimInfo {
  deviceName: string;
  claimNumber: number;
  repairInfos: RepairInfo[];
  acceptedReplacementCount: number;
}

interface RepairInfo {
  repairDate?: string;
  status: string;
  repairStatus?: string;
  serviceType?: string;
}
