



































import { Component, Vue } from 'vue-property-decorator'
import Fact from '@/components/facts/Fact.vue'
import DetailField from '@/components/DetailField.vue'
import DeviceIcon from '@/components/DeviceIcon.vue'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import { Getter } from 'vuex-class'
import { FnGetRepairByPolicyId } from '@/store/modules/claim/getters'
import { RepairModel } from '@/services/api/models/RepairModel'
import TotalCostRenderer from '@/components/facts/renderers/TotalCostRenderer.vue'

@Component({
  components: {
    DetailField,
    DeviceIcon,
    Fact,
    BackDashboardButton,
    TotalCostRenderer
  }
})
export default class ViewClaimDetail extends Vue {
  @Getter('claim/getRepairByPolicyId')
  private getRepairByPolicyId!: FnGetRepairByPolicyId

  private repair?: RepairModel

  get id() {
    return this.$route.params.id
  }

  renderer(factId: string) {
    switch (factId) {
      case 'device.serialjoyconleft':
      case 'device.serialjoyconright':
        return 'JoyConDetailFieldRenderer'
      case 'claim.issue':
        return 'ListDetailFieldRenderer'
      case 'outcome.customRepairStatus':
        return 'ClaimStatusFieldRenderer'
      case 'virtual.shippingCompany':
        return 'ShippingCompanyRenderer'
      case 'claim.issue.other':
      case 'virtual.repairComments':
        return 'LargeTextRenderer'
      default:
        return 'TextRenderer'
    }
  }

  created() {
    this.repair = this.getRepairByPolicyId(this.$route.params.id)
  }
}
