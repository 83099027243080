






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Stepper extends Vue {
  @Prop() private totalSteps!: number;
  @Prop() private currentStep!: number;
}
